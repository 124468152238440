import {
    isBefore,
    format,
    parseISO,
    formatDistanceToNow
} from 'date-fns';
import * as usersGroupsPermissions from '../Admin/UsersAndPermissions/UsersAndPermissionsList/UsersAndPermissionsList'
import type { GeoPosition } from '@geoapify/geocoder-autocomplete';
import Decimal from 'decimal.js';

class UtilService {


    limitString = (str: string, limit: number) => {
        if (!str || str?.length <= limit) {
            return str;
        }
        return str.slice(0, limit) + "...";
    };

    limitFinalString = (str: string, limit: number) => {
        if (!str || str?.length <= limit) {
            return str;
        }
        return str.slice(limit, str?.length - 1) + "...";
    };

    filterEmptyItem = (list: string[]) => {
        const listUpdated = list.filter(item => item !== "");
        return listUpdated;
    }

    formatNumber = (value: number) => {
        if (isNaN(value)) return "$0.00";
        const positiveValue = Math.max(0, value);
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(positiveValue);
    };

    formatNumberDecimal = (value: number | string) => {
        // Convert to Decimal, handling potential NaN or non-numeric input
        let decimalValue;
        try {
            decimalValue = new Decimal(value);
        } catch (error) {
            return "$0.00"; // Return default if conversion fails
        }

        if (decimalValue.isNaN()) {
            return "$0.00";
        }

        // Ensure it's not negative.
        const positiveValue = decimalValue.abs();

        // Use Decimal's toFixed to get the string representation *without* rounding.
        const formattedString = positiveValue.toFixed(2, Decimal.ROUND_DOWN);

        // Use Intl.NumberFormat for currency formatting *after* the precision is set.
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2 // This will now *not* round, because toFixed already did the work.
        }).format(Number(formattedString)); //Convert to number to format properly.

    };

    getUnitCost = (item: any) => {
        if (item?.markupValue > 0) {
            return this.formatNumber(item?.unitCost + item?.markupValue);
        } else {
            return this.formatNumber(item?.unitCost + (item?.unitCost * item?.markup) || 0);
        }
    }

    extractLastParam = (pathname: string) => {
        const urlParts = pathname.split('/');
        const lastParam = urlParts[urlParts.length - 1];
        return lastParam;
    };

    screenup = () => {
        window.scrollTo({
            top: 10,
            behavior: "auto",
            //   smooth
        });
    };

    getHeadersToken(): any {
        const token = localStorage.getItem('accessToken');
        if (token) {
            const headers: { [key: string]: string } = token
                ? { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }
                : { 'Content-Type': 'application/json' };
            return headers;
        }
    }

    getHeadersTokenFormData(): any {
        const token = localStorage.getItem('accessToken');
        if (token) {
            const headers: { [key: string]: string } = token
                ? { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' }
                : { 'Content-Type': 'multipart/form-data' };
            return headers;
        }
    }

    getHeadersApiToken(): any {
        const token = localStorage.getItem('apiToken');

        // Define the type of the headers object
        const headers: { [key: string]: string } = token
            ? { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }
            : { 'Content-Type': 'application/json' };

        return headers;
    }

    setLocalStorageLocationPermission(location: GeoPosition | 'denied'): any {
        localStorage.removeItem('coordinatesLocationPermission');
        setTimeout(() => {
            if (location === 'denied') {
                localStorage.setItem('coordinatesLocationPermission', 'denied');
            } else {
                localStorage.setItem('coordinatesLocationPermission', JSON.stringify(location));
            }
        }, 300);
    }

    getLocalStorageLocationPermission(): any {
        const coords: any = localStorage.getItem('coordinatesLocationPermission');
        return !this.isEmpty(coords) ? JSON.parse(coords) : null;
    }

    getLocalStorageOriginURL(): any {
        const requestOriginURL: any = localStorage.getItem('requestOriginURL');
        return !this.isEmpty(requestOriginURL) ? JSON.stringify(requestOriginURL) : null;
    }

    getLocalStorageRequestStartRecordingMicrophonePermissions(): any {
        const requestAudioPermission: any = localStorage.getItem('requestStartRecordingMicrophonePermissions');
        return !this.isEmpty(requestAudioPermission) ? JSON.stringify(requestAudioPermission) : null;
    }

    getLocalUserData(): any {
        const userData = localStorage.getItem('localUser');
        return userData ? JSON.parse(userData) : null;
    }

    normalizeUrlString(str: any) {
        if (str) {
            return str
                .trim()
                .toLowerCase()
                .replace(/[^\w\s-]/g, '')
                .replace(/[\s_]+/g, '-')
                .replace(/^-+|-+$/g, '');
        }
        return str;
    }

    getLocalDate() {
        const currentDate = new Date();
        const newYorkOffset = -4;
        const localDate = new Date(currentDate.getTime() + newYorkOffset * 60 * 60 * 1000);
        return localDate.toISOString().slice(0, 10);
    }

    getLocalDateTime() {
        const currentDate = new Date();
        const newYorkOffset = -4; // New York time is UTC-4 during Eastern Daylight Time (EDT)
        const localDate = new Date(currentDate.getTime() + newYorkOffset * 60 * 60 * 1000);
        return localDate.toISOString().slice(0, 16);
    }

    getCategoryFromURL() {
        const currentURL = window.location.href;
        const regexCategory = /\/service-providers\/([^/]+)-contractors/;
        const matchCategory = currentURL.match(regexCategory);
        const category = matchCategory ? matchCategory[1] : null;
        return category;
    }

    getSubCategoryFromURL() {
        const currentURL = window.location.href;
        const regexSubcategory = /\/service-providers\/[^/]+-contractors\/([^/]+)(?=-near-me)/;
        const matchSubcategory = currentURL.match(regexSubcategory);
        const subCategory = matchSubcategory ? matchSubcategory[1] : null;
        return subCategory;
    }

    /**
         * Checks if a value is considered empty.
         *
         * This function checks for various "empty" states, including:
         * - null
         * - undefined
         * - Empty string ("")
         * - String "null" (case-insensitive)
         * - String "undefined" (case-insensitive)
         * - Empty object ({})
         * - String containing only whitespace
         * - Empty array ([])
         *
         * @param value - The value to check.
         * @returns True if the value is considered empty, false otherwise.
         */
    isEmpty(value: any): boolean {
        if (value === null || value === undefined || value === '' || value === 'null' || value === 'undefined') {
            return true;
        }

        if (typeof value === 'string') {
            const trimmedValue = value.trim().toLowerCase();
            return trimmedValue === '' || trimmedValue === 'null' || trimmedValue === 'undefined';
        }

        if (typeof value === 'object') {
            if (Array.isArray(value)) {
                return value.length === 0; // Check for empty array
            }
            return Object.keys(value).length === 0; // Check for empty object
        }

        return false;
    }


    isZero(value: any): boolean {
        return (value == 0 ||
            value === "undefined" ||
            value == "null" ||
            value === undefined ||
            value === null);
    }

    normalizeUrlToString(text: string | null | undefined) {
        return text?.replaceAll("-", " ").toLocaleUpperCase();
    }

    getTimePassed = (date: Date) => {
        if (!date) {
            return "";
        }
        return formatDistanceToNow(new Date(date), { addSuffix: true });
    };

    getTimePassedAccountSignature = (futureDateString: string) => {
        const currentDate: any = new Date();
        const futureDate: any = new Date(futureDateString);

        if (futureDate <= currentDate) {
            // If the future date is in the past or present
            return "Expired!";
        }

        const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
        const differenceInMilliseconds = futureDate - currentDate;

        // Calculate the difference in days
        const daysRemaining = Math.ceil(differenceInMilliseconds / millisecondsPerDay);

        return `Expires in ${daysRemaining} days`;
    };


    formatDateTime(dateString: string) {
        if (dateString) {
            const formattedDateTime = format(new Date(dateString), 'MM/dd/yyyy, HH:mm');
            return formattedDateTime;
        }
    }

    formatDate(dateString: string) {
        if (dateString) {
            const formattedDate = format(new Date(dateString), 'MM/dd/yyyy');
            return formattedDate;
        }
    }

    formatDateTimeTZ(dateString: string) {
        if (dateString) {
            const date = new Date(dateString);
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            const formattedDateTime = new Intl.DateTimeFormat('en-US', {
                timeZone,
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            }).format(date);

            return formattedDateTime;
        }
        return null;
    }

    formatPhoneNumber(phoneNumber: string) {
        if (this.isEmpty(phoneNumber)) {
            return "";
        }
        const cleaned = ('' + phoneNumber).replace(/\D/g, ''); // Remove non-numeric characters
        if (cleaned.length !== 10) {
            return phoneNumber;
        }
        return `(${cleaned.substring(0, 3)}) ${cleaned.substring(3, 6)}-${cleaned.substring(6, 10)}`;
    }

    isProvider = () => {
        const loggedUser: any = this.getLocalUserData();
        const isProvider: boolean = (loggedUser?.userType == 'PROVIDER') || false;
        const isEmployeeAdmin: boolean = (loggedUser?.userType == 'EMPLOYEE' && loggedUser.groupPermission == 'admin') ?? false;
        return (isProvider || isEmployeeAdmin);
    }

    isAdminOrEmployee = () => {
        return this.isProvider() || this.isEmployee();
    }

    isSalesRep = () => {
        const loggedUser: any = this.getLocalUserData();
        const isSalesRep: boolean = (loggedUser?.userType == 'EMPLOYEE' && loggedUser.groupPermission == 'sales-rep') ?? false;
        return isSalesRep;
    }

    isUser = () => {
        const loggedUser: any = this.getLocalUserData();
        const isUser: boolean = loggedUser?.userType == 'USER' || false;
        return isUser;
    }

    isEmployee = () => {
        const loggedUser: any = this.getLocalUserData();
        const isEmployee: boolean = loggedUser?.userType === 'EMPLOYEE' || false;
        return isEmployee;
    }

    getAuthGroupPermission = (descPermission: string) => {
        if (this.isProvider()) {
            return true;
        }

        const loggedUser: any = this.getLocalUserData();
        const userGroupPermission = loggedUser?.groupPermission;
        const group: any = usersGroupsPermissions?.userGroups?.find((group: any) => group?.groupPermission === userGroupPermission);

        if (group?.permissions?.some((permission: any) => permission?.ref === descPermission)) {
            return true;
        }

        return false;
    }

    getAccountProviderId = () => {
        const loggedUser: any = this.getLocalUserData();
        const providerId = loggedUser?.account?.providerId;
        return providerId;
    }


    getStringDateNow = () => {
        const now = new Date();
        const dateString = `${now.getFullYear()}${String(now.getDate()).padStart(2, '0')}${String(now.getMonth() + 1).padStart(2, '0')}${String(now.getHours()).padStart(2, '0')}${String(now.getMinutes()).padStart(2, '0')}${String(now.getSeconds()).padStart(2, '0')}`;
        return dateString;
    }


    isPremiumUser = () => {
        const loggedUser: any = this.getLocalUserData();
        return (
            loggedUser?.account?.isActive &&
            loggedUser?.account?.status == "PAID"
        );
    }

    isHasDaysRemaining = () => {
        const loggedUser: any = this.getLocalUserData();
        const expirationDateStr = loggedUser.account?.planExpirationDate;
        if (expirationDateStr) {
            const expirationDate = new Date(expirationDateStr);
            if (expirationDate && this.isBeforeDateFromNow(expirationDate)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    isBeforeDateFromNow = (date: Date | string) => {
        const parsedDate = typeof date === 'string' ? parseISO(date) : date;
        return isBefore(new Date(), parsedDate);
    }

    isAdmins = (email: string) => {
        const adminEmails = ['lucascorreiaevangelista@gmail.com', 'michael@oci360.com', 'pedro@oci360.com', 'prontoservices360@gmail.com', 'info@oci360.com'];
        return adminEmails.includes(email);
    }

    getFullHtmlContent = (idHTML: string, fileName: string, documentDOM: any) => {
        const originalElement = documentDOM.getElementById(idHTML) as HTMLElement;

        // Clone the original element to avoid modifying the DOM
        const htmlContent: HTMLElement = originalElement?.cloneNode(true) as HTMLElement;

        // Remove elements with the "omit" class
        const elementsToOmit = htmlContent.querySelectorAll('.omit');
        elementsToOmit.forEach((element) => {
            element.remove();
        });

        // Function to extract all CSS rules from stylesheets
        const extractAllCss = () => {
            let allCss = '';
            for (const sheet of documentDOM.styleSheets) {
                if (sheet.cssRules) {
                    for (const rule of sheet.cssRules) {
                        allCss += rule.cssText + '\n';
                    }
                }
            }
            return allCss;
        };

        // Extract the entire CSS from the page
        const entireCss: string = extractAllCss();

        // Extract the HTML content as a string
        const fullHtml: string | null = htmlContent.outerHTML;

        // Combine HTML content and styles
        const fullHtmlContent = `<style>${entireCss}</style>${fullHtml}`;

        return { fileName, fullHtmlContent };
    }

    /**
    * Copy a string to clipboard
    * @param  {String} string         The string to be copied to clipboard
    * @return {Boolean}               returns a boolean correspondent to the success of the copy operation.
    * @see https://stackoverflow.com/a/53951634/938822
    */
    copyToClipboard = (text: string) => {
        let textarea: any;
        let result: any;

        try {
            textarea = document.createElement('textarea');
            textarea.setAttribute('readonly', true);
            textarea.setAttribute('contenteditable', true);
            textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
            textarea.value = text;

            document.body.appendChild(textarea);

            textarea.focus();
            textarea.select();

            const range = document.createRange();
            range.selectNodeContents(textarea);

            const sel: any = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);

            textarea.setSelectionRange(0, textarea.value.length);
            result = document.execCommand('copy');
        } catch (err) {
            console.error(err);
            result = null;
        } finally {
            document.body.removeChild(textarea);
        }

        // manual copy fallback using prompt
        if (!result) {
            const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
            const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
            result = prompt(`Press ${copyHotkey}`, text); // eslint-disable-line no-alert
            if (!result) {
                return false;
            }
        }
        return true;
    }

    getCreatedAt4DigitShortCode(): number {
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString();
        const timestamp = new Date(formattedDate).getTime();
        // Generate a random number to add to the timestamp to make it more unique
        const randomComponent = Math.floor(Math.random() * 1000);
        // Combine timestamp with random component and take modulo 10000 to ensure it is a 4-digit number
        const fourDigitSortNumber = (timestamp + randomComponent) % 10000;
        return fourDigitSortNumber;
    }

    isStatusApproved = (status: string) => {
        return 'APPROVED' === status;
    }

}

export default new UtilService();