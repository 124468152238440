import { useEffect, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import style from "./UserMenu.module.css";
import UtilService from "../../../../services/UtilService";
import WindowService from "../../../../services/WindowService";
import ApiTokenService from "../../../../services/ApiTokenService";
import { isMobile } from "react-device-detect";
import React from "react";

const UserMenu = () => {
    const loggedUser: any = UtilService.getLocalUserData();
    const navigate = useNavigate();
    const apiTokenService = ApiTokenService(navigate);
    const isProvider = UtilService.isProvider();
    const isEmployee = UtilService.isEmployee();
    const routeChange = () => {
        let path = `${process.env.PUBLIC_URL}/`;
        navigate(path);
    }

    const logOut = () => {
        apiTokenService.logOut();
    }
    const [isDropdownDisabled, setDropdownDisabled] = useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [userTitle, setUserTitle] = useState("");
    const [userDescription, setUserDescription] = useState("");
    const [accountType, setAccountType] = useState("");
    const [accountExpiration, setAccountExpiration] = useState("");
    const windowService = WindowService();
    const handleDropdownToggle = () => {
        if (isDropdownDisabled) {
            return;
        }

        //setDropdownOpen(!isDropdownOpen);
    };
    const [showAdminMenu, setShowAdminMenu] = useState(false);
    const [showAccountSettingsMenu, setShowAccountSettingsMenu] = useState(false);

    const review = (event: any) => {
        event.preventDefault();
        windowService.sendMessageRequestReview();
    };

    const checkForUpdates = (event: any) => {
        event.preventDefault();
        windowService.sendMessageReloadApp();
        window.location.href = window.location.href.split('?')[0] + '?t=' + Date.now();
    };

    const memoizedAccountExpiration = useMemo(() => {
        if (loggedUser?.account?.planExpirationDate) {
            return UtilService.getTimePassedAccountSignature(loggedUser.account.planExpirationDate);
        }
        return "";
    }, [loggedUser?.account?.planExpirationDate]);

    useEffect(() => {
        setAccountExpiration(memoizedAccountExpiration);
    }, [memoizedAccountExpiration]);

    useEffect(() => {
        const setUserTitleAndDescription = () => {
            if (loggedUser) {
                if (UtilService.isProvider()) {
                    setUserTitle(loggedUser?.companyName);
                    setAccountType((loggedUser?.account?.status === "PAID" ? 'PREMIUM' : 'FREE') + ' account')
                } else {
                    setUserTitle(loggedUser?.fullName);
                }
                setUserDescription(loggedUser?.email);
            } else {
                setUserTitle("Your name or company");
                setUserDescription("Not logged in");
            }
        }
        setUserTitleAndDescription();
    });

    const handleAdminClick = (event: any) => {
        event.stopPropagation(); // This stops the event from reaching the parent dropdown
        setShowAdminMenu(!showAdminMenu);
    };

    const handleAccountSettingsClick = (event: any) => {
        event.stopPropagation(); // This stops the event from reaching the parent dropdown
        setShowAccountSettingsMenu(!showAccountSettingsMenu);
    };

    return (
        <div>
            <Dropdown className=" main-profile-menu nav nav-item nav-link ps-lg-2">
                <Dropdown.Toggle
                    className={`new nav-link profile-user d-flex ${style.optionsMenu}`}
                    variant=""
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="side-menu__icon"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z" />
                    </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu className="border">
                    <div className="menu-header-content p-3 border-bottom">
                        <div className="d-flex wd-100p">
                            <div className="main-img-user">
                                {!loggedUser?.logo && (
                                    <img
                                        alt=""
                                        src={require("../../../../assets/img/faces/user-avatar.jpg")}
                                        className=""
                                    />
                                )}
                                {loggedUser?.logo && (
                                    <img
                                        alt=""
                                        src={loggedUser.logo}
                                        className=""
                                    />
                                )}
                            </div>
                            <div className="ms-3 my-auto">
                                <h6 className="tx-15 font-weight-semibold mb-0">
                                    {UtilService.limitString(userTitle?.toLocaleUpperCase(), 15)}
                                </h6>
                                <span className="dropdown-title-text subtext op-6  tx-9">
                                    {userDescription}
                                </span>
                                {loggedUser && isProvider && (
                                    <>
                                        <h6 className="tx-15 font-weight-semibold mb-0">
                                            <i className="fa fa-shield-alt"></i>&nbsp;<small>{accountType}</small>
                                        </h6>
                                        <h6 className="tx-15 font-weight-semibold mb-0">
                                            <i className="fa fa-clock"></i>&nbsp;<small>{accountExpiration}</small>
                                        </h6>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    {isProvider && (
                        <>
                            {UtilService.isAdmins(loggedUser?.email) && (
                                <>
                                    <Dropdown.Item onClick={handleAdminClick}>
                                        <span style={{ color: 'black' }}><i className="fa fa-user-lock" ></i> Admin</span>
                                    </Dropdown.Item>
                                    {showAdminMenu && (
                                        <div>
                                            <Dropdown.Item className="dropdown-item" href={`${process.env.PUBLIC_URL}/admin/dashboard/providers`}>Providers</Dropdown.Item>
                                            <Dropdown.Item className="dropdown-item" href={`${process.env.PUBLIC_URL}/admin/dashboard/users`}>Users</Dropdown.Item>
                                            <Dropdown.Item className="dropdown-item" href={`${process.env.PUBLIC_URL}/admin/dashboard/support`}>Support</Dropdown.Item>
                                            <Dropdown.Item className="dropdown-item" href={`${process.env.PUBLIC_URL}/admin/dashboard/service-order`}>Service Orders</Dropdown.Item>
                                            <Dropdown.Item className="dropdown-item" href={`${process.env.PUBLIC_URL}/admin/record-audio-meeting`}>Record audio meeting</Dropdown.Item>
                                            <Dropdown.Item className="dropdown-item" href={`${process.env.PUBLIC_URL}/admin/tests`}>Tests page</Dropdown.Item>
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}
                    {/*
                        <Dropdown.Item className="dropdown-item" href={`${process.env.PUBLIC_URL}/`}>
                            <i className="fa fa-home"></i>Home
                        </Dropdown.Item>
                    */}
                    {!loggedUser && (
                        <Dropdown.Item className="dropdown-item" href={`${process.env.PUBLIC_URL}/authentication/login`}>
                            <i className="fa fa-sign-in-alt"></i>Login
                        </Dropdown.Item>
                    )}
                    {(!isProvider && !isEmployee) && (
                        <Dropdown.Item className="dropdown-item" href={`${process.env.PUBLIC_URL}/authentication/signup`}>
                            <i className="far fa-user-circle"></i>Join as a professional
                        </Dropdown.Item>
                    )}
                    {loggedUser && (
                        <>
                            <Dropdown.Item className="dropdown-item" href={`${process.env.PUBLIC_URL}/admin/service-order`}>
                                <i className="far fa-calendar-alt"></i>Service orders
                            </Dropdown.Item><Dropdown.Item className="dropdown-item" href={`${process.env.PUBLIC_URL}/admin/new-service-order`}>
                                <i className="far fa-calendar-plus"></i>New service order
                            </Dropdown.Item>
                        </>
                    )}
                    {isProvider && (
                        <>
                            <Dropdown.Item className="dropdown-item" href={`${process.env.PUBLIC_URL}/admin/service-order-templates`}>
                                <i className="far fa-edit"></i>SO Templates
                            </Dropdown.Item>
                            <Dropdown.Item className="dropdown-item" href={`${process.env.PUBLIC_URL}/admin/clients`}>
                                <i className="far fa-user-circle"></i>Clients
                            </Dropdown.Item>
                            <Dropdown.Item className="dropdown-item" href={`${process.env.PUBLIC_URL}/admin/agents/voice-agent/onboarding`}>
                                <i className="fas fa-robot"></i>AI Voice Agents
                            </Dropdown.Item>
                            <Dropdown.Item className="dropdown-item" href={`${process.env.PUBLIC_URL}/admin/onboarding`}>
                                <i className="fas fa-link"></i>Stripe Integration
                            </Dropdown.Item>
                            <Dropdown.Item className="dropdown-item" href={`https://crm.prontoservices360.com`} target={'_blank'}>
                                <i className="fas fa-inbox"></i>Open CRM
                            </Dropdown.Item>
                            <Dropdown.Item onClick={handleAccountSettingsClick}>
                                <i className="fa fa-cogs"></i> Account Settings
                            </Dropdown.Item>
                            {showAccountSettingsMenu && (
                                <div>
                                    <Dropdown.Item className="dropdown-item" href={`${process.env.PUBLIC_URL}/admin/settings/email-notifications`}>E-mail notifications</Dropdown.Item>
                                    <Dropdown.Item className="dropdown-item" href={`${process.env.PUBLIC_URL}/admin/settings/discount-types`}>Discount Types</Dropdown.Item>
                                    <Dropdown.Item className="dropdown-item" href={`${process.env.PUBLIC_URL}/admin/settings/users-and-permissions`}>Users and permissions</Dropdown.Item>
                                    <Dropdown.Item className="dropdown-item" href={`${process.env.PUBLIC_URL}/admin/settings`}>Settings</Dropdown.Item>
                                </div>
                            )}
                        </>
                    )}
                    {loggedUser && (
                        <>
                            <Dropdown.Item className="dropdown-item" href={`${process.env.PUBLIC_URL}/admin/support`}>
                                <i className="fas fa-question-circle"></i>Support
                            </Dropdown.Item>
                            <Dropdown.Item className="dropdown-item" onClick={(event) => checkForUpdates(event)}>
                                <i className="fas fa-download"></i>Check for updates
                            </Dropdown.Item>
                        </>
                    )}

                    <div style={{ display: "none" }}>
                        <Dropdown.Item className="dropdown-item" href={`${process.env.PUBLIC_URL}/admin/service-order`}>
                            <i className="far fa-calendar-plus"></i>My orders
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown-item" href={`${process.env.PUBLIC_URL}/pages/profile`}>
                            <i className="far fa-user-circle"></i>Profile
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown-item" href={`${process.env.PUBLIC_URL}/pages/mail/chat`}>
                            <i className="far fa-smile"></i> Chat
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown-item" href={`${process.env.PUBLIC_URL}/pages/mail/mail`}>
                            <i className="far fa-comment-dots"></i>Messages
                        </Dropdown.Item>
                        <Dropdown.Item
                            className="dropdown-item"
                            as={Link}
                            to={`${process.env.PUBLIC_URL}/pages/mail/mailsettings`}
                        >
                            <i className="far fa-settings"></i> Settings
                        </Dropdown.Item>
                    </div>
                    {isMobile && (
                        <Dropdown.Item className="dropdown-item" onClick={(event) => review(event)}>
                            <i className="far fa-star"></i>Evaluate app
                        </Dropdown.Item>
                    )}
                    {loggedUser && (
                        <Dropdown.Item className="dropdown-item"
                            onClick={() => { logOut() }}
                        >
                            <i className="far fa-arrow-alt-circle-left" ></i> Sign Out
                        </Dropdown.Item>
                    )}
                    <Dropdown.Item className="dropdown-item"
                    >
                        <span className="w-100 align-items-center text-center">v: 2.1.9</span>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div >
    );
}

export default UserMenu;